export const posts = [
  {
    id: "0",
    title: "Choosing the Right Dumpster Rental Company in Chula Vista",
    excerpt:
      "Looking for a reliable dumpster rental company in Chula Vista? In this post, we discuss what factors to consider when selecting a dumpster rental service to ensure efficient waste management.",
    slug: "choosing-right-dumpster-rental-company-chula-vista",
    tags: [""],
    metaDescription:
      "Choosing a dumpster rental company in Chula Vista? Learn what to look for when selecting a reliable and efficient dumpster rental service.",
       lastModified: new Date('2023-12-03').toISOString()
  },
  {
    id: "1",
    title: "How to Prepare for a Dumpster Rental in Chula Vista",
    excerpt:
      "Renting a dumpster in Chula Vista? This post will guide you on how to prepare your site for dumpster delivery to ensure a smooth and efficient process.",
    slug: "prepare-dumpster-rental-chula-vista",
    tags: [""],
    metaDescription:
      "Learn how to prepare your site for dumpster delivery in Chula Vista to ensure a smooth and efficient waste disposal process.",
       lastModified: new Date('2023-12-03').toISOString()
  },
  {
    id: "2",
    title: "Benefits of Dumpster Rentals for Home Renovations in Chula Vista",
    excerpt:
      "Planning a home renovation in Chula Vista? Learn about the benefits of renting a dumpster for your project to ensure efficient waste disposal.",
    slug: "benefits-dumpster-rentals-home-renovations",
    tags: [""],
    metaDescription:
      "Discover the benefits of renting a dumpster for your home renovation project in Chula Vista.",
       lastModified: new Date('2023-12-03').toISOString()
  },
  {
    id: "3",
    title: "Eco-Friendly Waste Management with Dumpster Rentals in Chula Vista",
    excerpt:
      "Concerned about the environment? Learn how renting a dumpster in Chula Vista can be an eco-friendly solution for your waste management needs.",
    slug: "eco-friendly-waste-management-dumpster-rentals",
    tags: [""],
    metaDescription:
      "Discover how dumpster rentals in Chula Vista can contribute to eco-friendly waste management practices.",
       lastModified: new Date('2023-12-03').toISOString()
  },
];
