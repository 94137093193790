import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const WasteManagement = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Promoting Eco-Responsible Waste Management in Chula Vista, CA
        </Heading>
        <Text>
          In our collective journey toward a healthier planet, responsible waste
          management stands as a crucial pillar. At Chula Vista Dumpster Rental,
          we are passionately dedicated to providing services that not only meet
          our clients' needs but also contribute to the well-being of Mother
          Earth.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Green-Focused Techniques:</strong> Our strategies prioritize
            recycling and reduce landfill contributions, ensuring that every
            disposal contributes to building a better world.
          </ListItem>
          <ListItem>
            <strong>Commit to Clean:</strong> Choose a service that seamlessly
            blends efficiency with environmental stewardship, ensuring a clean
            and sustainable approach to waste management.
          </ListItem>
          <ListItem>
            <strong>Local Dedication:</strong> We envision a Chula Vista that
            remains pristine and eco-balanced for future generations to cherish,
            and we are committed to making that vision a reality.
          </ListItem>
          <ListItem>
            <strong>Shape a Greener Tomorrow:</strong> Collaborate with us to
            foster a more environmentally conscious Chula Vista community,
            taking meaningful steps toward a greener and sustainable tomorrow.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
