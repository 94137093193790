import React from "react";
import { Stack, Text } from "@chakra-ui/react";

export const BlogPost4 = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Responsible waste management is integral to preserving our environment.
        At Chula Vista Dumpster Rentals, we're dedicated to eco-friendly waste
        disposal practices. Here's how renting a dumpster can contribute to
        environmentally friendly waste management:
      </Text>
      <Text fontWeight={"bold"}>Proper Disposal</Text>
      <Text>
        Renting a dumpster ensures that your waste is disposed of correctly. We
        strictly adhere to local and federal waste disposal regulations to
        prevent illegal dumping, safeguarding the environment from harm.
      </Text>
      <Text fontWeight={"bold"}>Recycling</Text>
      <Text>
        Our commitment extends to recycling as much waste as possible. When you
        rent a dumpster with us, be assured that we meticulously sort through
        the waste, sending recyclable materials to appropriate recycling
        facilities.
      </Text>
      <Text fontWeight={"bold"}>Reduction of Carbon Footprint</Text>
      <Text>
        Renting a dumpster minimizes the need for multiple trips to the dump,
        leading to fuel savings and a reduction in carbon emissions. It's a
        practical way to contribute to environmental conservation.
      </Text>
      <Text fontWeight={"bold"}>Prevents Pollution</Text>
      <Text>
        With a dumpster, your waste is contained and disposed of properly,
        preventing it from ending up in undesired places such as waterways or
        natural habitats. This helps prevent pollution and preserves the
        ecological balance.
      </Text>
      <Text>
        In conclusion, renting a dumpster from Chula Vista Dumpster Rentals is
        an environmentally friendly method of managing waste. Through proper
        disposal, active recycling, carbon footprint reduction, and pollution
        prevention, you're actively participating in the preservation of our
        environment for future generations.
      </Text>
    </Stack>
  );
};
