import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { Card } from './Card'

export const PricingCard = (props) => {
  const { data, icon, button, ...rest } = props
  const { description, price, name } = data
  const accentColor = useColorModeValue('green.600', 'green.200')

  return (
    <Card rounded={{ sm: 'xl' }} {...rest} textAlign={'center'}>
      <VStack spacing={6} textAlign={'center'}>
        {/*<Icon aria-hidden as={icon} fontSize="4xl" color={accentColor} />*/}
        <Heading size="md" fontWeight="extrabold" textAlign={'center'}>
          {name}
        </Heading>
      </VStack>
      <Flex align="flex-end" justify="center" fontWeight="extrabold" color={accentColor} my="2" textAlign={'center'}>
        <Heading size="lg" fontWeight="inherit" lineHeight="0.9em" color={'#ffa903'}>
          {price}
        </Heading>
      </Flex>
      <Box minH={'100px'}>
        <Text fontSize={'sm'} py={5} textAlign={'center'}>
          {description}
        </Text>
      </Box>
      {button}
    </Card>
  )
}
