import React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const EventWasteManagement = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Chula Vista's Eco-Conscious Waste Management Partner
        </Heading>
        <Text>
          Embrace a commitment to a healthier planet with Chula Vista Dumpster
          Rental, where responsible waste management is at the core of our
          mission. We are dedicated to delivering services that meet the needs
          of our clients while actively contributing to the well-being of Mother
          Earth.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Sustainable Practices:</strong> Our approach prioritizes
            green-focused techniques, emphasizing recycling and minimizing
            landfill contributions to make each disposal a positive step towards
            a better world.
          </ListItem>
          <ListItem>
            <strong>Clean Commitment:</strong> Choose a service that seamlessly
            blends efficiency with environmental stewardship, ensuring your
            waste disposal aligns with a dedication to a cleaner, greener
            future.
          </ListItem>
          <ListItem>
            <strong>Local Environmental Dedication:</strong> We envision a Chula
            Vista that remains pristine and eco-balanced for the enjoyment of
            future generations, embodying our commitment to local environmental
            sustainability.
          </ListItem>
          <ListItem>
            <strong>Shape a Greener Tomorrow Together:</strong> Collaborate with
            us to foster a more environmentally conscious Chula Vista community,
            contributing to the collective effort of shaping a greener tomorrow.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
