import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Image, Link, Text } from "@chakra-ui/react";
import useBlogPosts from "../../Hooks/useBlogPosts";
import { getTowingImage } from "../../Utils/metaHelpers";
import PageHeader from "../../Components/common/PageHeader";
import dumpster1 from "../../assets/images/dumpster1.jpg";
import dumpster2 from "../../assets/images/dumpster2.jpg";
import dumpster3 from "../../assets/images/dumpster3.jpg";
import dumpster4 from "../../assets/images/dumpster4.jpg";
import { BlogPost1 } from "../../Constants/blogPost1";
import { BlogPost2 } from "../../Constants/blogPost2";
import { BlogPost3 } from "../../Constants/blogPost3";
import { BlogPost4 } from "../../Constants/blogPost4";
import { SiteData } from "../../Constants/siteData";
import { posts } from "../../Constants/posts";

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Text fontWeight={"bold"}>{text}</Text>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

const blogPostMapping = (id) => {
  switch (id) {
    case '0':
      return <BlogPost1 title={posts[0].title} img={dumpster1}
                        subtitle={posts[0].excerpt}/>
    case '1':
      return <BlogPost2 title={posts[1].title} img={dumpster2} subtitle={
        posts[1].excerpt
      }/>
    case '2':
      return <BlogPost3 title={posts[2].title} img={dumpster3}
                        subtitle={posts[2].excerpt}/>

    case '3':
      return <BlogPost4 title={posts[3].title} img={dumpster4}
                        subtitle={posts[3].excerpt}/>
    default:
      break;
  }
}

const BlogDetails = ({ post }) => {
  const { posts } = useBlogPosts();
  return (
    <>
      <PageHeader header={post.title} />

      <div className="main_wrapper section">
        <div className="container">
          <div className="blog_details">
            <div className="row">
              <div className="col-lg-8 col-md-12 pe-4">
                <div className="blog_details_inner">
                  <div className="post_content">
                    <div className="post_header">
                      <div className="categ">
                        <a href="/blog">{SiteData.blog.h1}</a>
                      </div>
                      <h3 className="post_title">{post.title}</h3>
                    </div>
                    <div className="fulltext">
                      {Array.isArray(post.tags)
                        ? blogPostMapping(post.id)
                        : documentToReactComponents(post.body, options)}
                    </div>
                    <div className="post_img">
                      <img src={post.image} alt="blog" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="sidebar">
                  <div
                    id="recent-posts-1"
                    className="widget widget_recent_posts"
                  >
                    <h4 className="widget_title">
                      Recent Posts
                      <span className="title_line"></span>
                    </h4>
                    <div className="sidebar_recent_posts">
                      <ul className="recent_post_list">
                        {posts
                          .filter((p) => p.title !== post.title)
                          .map((pst) => {
                            return (
                              <li className="recent_post_item" key={pst.slug}>
                                <div className="recent_post_image">
                                  <a href={`/blog/${pst.slug}`}>
                                    <Image
                                      className="primary_img"
                                      src={pst.image || getTowingImage(pst.id)}
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="recent_post_content">
                                  <Link
                                    href={`/blog/${pst.slug}`}
                                    _hover={{ color: "initial" }}
                                  >
                                    <h5>{pst.title}</h5>
                                    <Text
                                      noOfLines={3}
                                      _hover={{ color: "initial" }}
                                    >
                                      {pst.excerpt}
                                    </Text>
                                  </Link>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
