import React from 'react';
import Banner from '../../Containers/Banner';
import Blog from '../../Containers/Blogs/Blog';
import FunFact01 from '../../Containers/Funfacts/FunFact01';
import Testimonial01 from '../../Containers/Testimonials/Testimonial01';
import Slider from '../../Containers/ThemeSliders/Slider';
import {SiteData} from "../../Constants/siteData";
import {SEO} from "../../Components/SEO/SEO";
import {HomeSection} from "../../Constants/homeSection";
import Map from "../../Components/ContactElements/Map";
import {Box} from "@chakra-ui/react";
import {PricingWithCornerBadge} from "../../Components/PricingWithCornerBadge/PricingWithCornerBadge";
import ExperiencesWide from "../../Containers/ExperiencesWide/Experience";

const Home01 = () => {
    return (
        <div className="main_wrapper">
            <SEO/>
            <Slider/>
            <Banner
                title={SiteData.contact.closingText}
                heading='Call Us Anytime'
                phone={SiteData.phoneNumber}
                bannerType='banner banner_bg_color'
            />
            <ExperiencesWide/>
            {/*<ContactFormSection data={[{*/}
            {/*    id: '1',*/}
            {/*    iconClass: 'ion-ios-telephone-outline',*/}
            {/*    infoBody01: 'Call us Today!',*/}
            {/*    infoBody02: SiteData.phoneNumber,*/}
            {/*    link: SiteData.telLink,*/}
            {/*    cta: 'Provide some information and get a FREE quote today',*/}
            {/*    heading: 'Get a FREE estimate'*/}
            {/*}]}/>*/}
            <PricingWithCornerBadge/>
            <Blog/>
            <FunFact01/>
            <Testimonial01 pb='pd_btom_80'/>
            <HomeSection/>
            <Box pt={0} mb={0} minW={'full'}>
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        <Map/>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default Home01;