import React from 'react';
import {Heading} from "@chakra-ui/react";

const BannerText = (props) => {
    return (
        <div className="banner_text text-center">
            <img src="images/phone3.png" alt=""/>
            <Heading as={'h2'} color={'#fff'}>{props.title}</Heading>
        </div>
    );
};

export default BannerText;