import {Button, Link} from '@chakra-ui/react'
import * as React from 'react'
import {SiteData} from "../../Constants/siteData";

export const ActionButton = (props) => (
    <Button
        colorScheme="green"
        size="md"
        w="full"
        fontWeight="extrabold"
        as={Link}
        bg={'#1A202C'}
        _hover={{textDecoration: 'none', color: '#fff'}}
        href={SiteData.telLink}
        py={{md: '5'}}
        {...props}
    />
)
