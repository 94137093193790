import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

export const RollOffDumpster = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Efficient Roll-Off Dumpster Rentals in Chula Vista, CA
        </Heading>
        <Text>
          Undertaking a project that generates a substantial amount of waste?
          Look no further than Chula Vista Dumpster Rental for the ultimate
          solution with our roll-off dumpster services. Ideal for both
          short-term and extensive projects, our roll-off containers make waste
          disposal a breeze, adapting to your unique needs.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Versatile Sizes:</strong> Whether it's home renovations or
            construction sites, our roll-off dumpsters come in sizes that cater
            to every requirement, ensuring you have the right container for your
            project.
          </ListItem>
          <ListItem>
            <strong>Seamless Delivery & Pickup:</strong> Our streamlined
            drop-off and collection system allow you to focus on your project
            without any waste-related worries, providing you with effortless
            service.
          </ListItem>
          <ListItem>
            <strong>Environmentally Conscious Handling:</strong> With a
            dedicated commitment to the environment, we ensure responsible
            disposal, prioritizing sustainability every step of the way in our
            roll-off dumpster services.
          </ListItem>
          <ListItem>
            <strong>Trusted Support:</strong> Chula Vista contractors and
            residents place their trust in our expertise, timely responses, and
            exceptional customer care, making us the go-to choice for roll-off
            dumpster rentals.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
