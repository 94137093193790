import {Box, Heading, SimpleGrid, Text, useColorModeValue} from '@chakra-ui/react'
import * as React from 'react'
import {SiMicrosoft} from 'react-icons/si'
import {ActionButton} from './ActionButton'
import {PricingCard} from './PricingCard'
import {SiteData} from "../../Constants/siteData";

export const PricingWithCornerBadge = () => (
    <Box
        as="section"
        bg={useColorModeValue('gray.50', 'gray.800')}
        pt="8"
        pb='16'
        px={{base: '4', md: '8'}}
    >
        <Box
            maxW="4xl"
            mx="auto"
            px={{base: '6', lg: '8'}}
            py={{base: '0', sm: '4'}}
            textAlign="center"

        >
            <Heading size="xl" fontWeight="extrabold" letterSpacing="tight" as={'h2'}>
                {SiteData.homepage.h2}
            </Heading>
            <Text mt="4" fontSize="lg" maxW="xl" mx={'auto'}>
                {SiteData.homepage.h3}
            </Text>
        </Box>
        <SimpleGrid
            columns={{base: 1, lg: 6}}
            spacing={{base: '8', lg: '2'}}
            maxW="7xl"
            mx="auto"
            justifyItems="center"
            alignItems="center"
            mt={{base: '6', lg: 6}}
        >
            {SiteData.homepage.sizes.map((size) => {
                return (
                    <PricingCard
                        key={size.title}
                        data={{
                            price: size.title,
                            name: size.nickname,
                            description: size.description
                        }}
                        icon={SiMicrosoft}
                        button={
                            <ActionButton>
                                Call Now
                            </ActionButton>
                        }
                    />
                )
            })}

        </SimpleGrid>
    </Box>
)
