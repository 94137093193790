import React from 'react';
import AboutBelowContent02 from '../../Components/ExperienceBlocks/AboutBelowContent02';
import {SiteData} from "../../Constants/siteData";
import {Box, Heading} from "@chakra-ui/react";
import { MdOutlineConstruction } from "react-icons/md";
const ExperienceAbout = () => {
    return (
      <div className="experience">
          <Box py={20}>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6">
                          <div className="group_image_holder type_1">
                              <MdOutlineConstruction size={'3xl'}/>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                          <div className="experience_content about">
                              <div className="section_header">
                                  <h6 className="section_sub_title">{SiteData.about.h2[1]}</h6>
                                  <Heading as={'h2'} py={3}>{SiteData.about.h2[2]}</Heading>
                                  <p className="section_desc">{SiteData.about.headingText.services}</p>
                                  <div className="about_below">
                                      {
                                          SiteData.about.services.splice(0,2).map(data =>
                                            <AboutBelowContent02
                                              key={data.id}
                                              data={data}
                                            />
                                          )
                                      }
                                  </div>
                              </div>
                              <a className="button" href="/services">See our services</a>
                          </div>
                      </div>
                  </div>
              </div>
          </Box>
      </div>
    );
};

export default ExperienceAbout;