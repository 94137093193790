import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

export const ResidentialDumpster = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Effortless Residential Dumpster Rentals in Chula Vista, CA
        </Heading>
        <Text>
          Whether you're planning a home makeover or gearing up for a spring
          cleaning session, Chula Vista Dumpster Rental provides seamless
          solutions for all your residential waste disposal needs. Our versatile
          dumpsters come in various sizes, ensuring a perfect match for the
          scope of your project.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Diverse Dumpster Dimensions:</strong> From petite home
            clear-outs to extensive remodels, we have a dumpster tailored to
            your specific needs.
          </ListItem>
          <ListItem>
            <strong>Prompt Assistance:</strong> Enjoy our reliable drop-offs and
            pickups, ensuring your projects proceed without a hitch, keeping
            your timeline on track.
          </ListItem>
          <ListItem>
            <strong>Environmentally Responsible Disposal:</strong> We are
            dedicated to sustainable practices, guaranteeing that your refuse is
            managed with the well-being of the planet in mind.
          </ListItem>
          <ListItem>
            <strong>Customer-First Ethos:</strong> Chula Vista locals vouch for
            our unwavering commitment to excellence and unmatched customer
            support.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
