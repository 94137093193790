import React from 'react';
// import CountUp from 'react-countup';

const FunBox01 = ({data}) => {
    const {name, number, icon: Icon} = data;
    return (
        <div className="col-lg-6 col-sm-6">
            <div className="funbox1">
                <div className="fun_img">
                    <Icon fontSize={'2.5rem'} color={'white'}/>
                </div>
                <div className="fun_content">
                    <h1>{number}</h1>
                    <p>{name}</p>
                </div>
            </div>

        </div>
    );
};

export default FunBox01;