// @ts-ignore
import React from "react";
import {posts} from "./posts";
import chulavistadumpsterrentalsmeta from "../assets/images/chulavistadumpsterrentalsmeta.jpg";
import {Box, Button, Heading, Hide, Image, Link, List, ListItem, Stack, Text, UnorderedList,} from "@chakra-ui/react";
import {ResidentialDumpster} from "../Components/ResidentialDumpster/ResidentialDumpster";
import {CommercialDumpster} from "../Components/CommercialDumpster/CommercialDumpster";
import {RollOffDumpster} from "../Components/RollOffDumpster/RollOffDumpster";
import {WasteManagement} from "../Components/WasteManagement/WasteManagement";
import {AiFillStar, AiOutlineSmile} from "react-icons/ai";
import {FaHandHoldingUsd} from "react-icons/fa";
import {MdLocalShipping} from "react-icons/md";
import {SameDayDumpster} from "../Components/SameDayDumpster/SameDayDumpster";
import {EventWasteManagement} from "../Components/EventWasteManagement/EventWasteManagement";
import {JunkRemovalServices} from "../Components/JunkRemovalServices/JunkRemovalServices";
import {LongTermDumpsterRentals} from "../Components/LongTermDumpsterRentals/LongTermDumpsterRentals";
import dumpster4 from '../assets/images/dumpster4.jpg'

export const SiteData = {
    hoursOfOpperation: "8am - 5pm Monday - Saturday",
    city: "Chula Vista, CA",
    phoneNumber: "(619) 650-6638",
    telLink: "tel:6196506638",
    title: "Dumpster Rentals in Chula Vista,CA | 619-650-6638",
    buttonCtaText: "Call to Get a Quote for a Dumpster",
    keywords:
        "Dumpster, rental, efficient, affordable, reliable, Chula Vista, CA, waste management, junk removal",
    footerText: "Chula Vista Dumpster Rentals, All rights reserved.",
    ogImage: chulavistadumpsterrentalsmeta,
    navLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services/",
        },
        {
            title: "Blog",
            href: "/blog/",
        },
        {
            title: "About",
            href: "/about/",
        },
        {
            title: "Contact Us",
            href: "/contact/",
        },
    ],
    signupForm: {
        intro: () => (
            <>
                Chula Vista Dumpster Rentals provides quick and reliable dumpster rental
                services to Chula Vista, California and surrounding areas. Whether you
                are dealing with construction debris, household{" "}
                <a
                    href="https://www.northportdumpsterrental.com"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    junk removal
                </a>
                ,{" "}
                <a
                    href="https://www.maui-rolloffs-junk-removal.com/"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    roll offs
                </a>
                , or require a dumpster for a big event, we have you covered. Our team
                is always ready to deliver and pick up your dumpster at your
                convenience.
            </>
        ),
        servicesTitle: "Our Services:",
    },
    homepage: {
        servicesTitle: "Why Chula Vista Chooses Us for Dumpster Rentals",
        servicesHeader:
            "Comprehensive Dumpster Rental Solutions for Every Requirement",
        url: "https://www.chulavistadumpsterrentals.com",
        metaData: {
            title:
                "Chula Vista Dumpster Rentals: Your Trusted Partner for Waste Management | Call 619-650-6638",
            description:
                "Chula Vista Dumpster Rentals: Your trusted partner for all your waste management needs. Contact us today for prompt and professional dumpster rental services in Chula Vista.",
        },
        h1: "Eco-Friendly Dumpster Rentals in Chula Vista",
        subtitle:
            "Your Reliable Waste Management Partner in Chula Vista, California",
        h2: "Find the Perfect Dumpster Size for Your Project",
        h3: "We offer a variety of dumpster sizes to cater to your specific needs. No project is too big or too small for us.",
        sizes: [
            {
                title: "3 Yard",
                nickname: "The Shed",
                description:
                    "Ideal for small cleanouts and home projects. A compact size that's easy to place.",
            },
            {
                title: "4 Yard",
                nickname: "The Garage",
                description:
                    "Perfect for medium cleanouts or small renovation tasks. Offers a bit more space.",
            },
            {
                title: "10 Yard",
                nickname: "The Sprint",
                description:
                    "Great for garage cleanouts or single room remodels. A versatile choice for homeowners.",
            },
            {
                title: "20 Yard",
                nickname: "The Steed",
                description:
                    "A popular choice for bigger projects. Suitable for large cleanouts and roof replacements.",
            },
            {
                title: "30 Yard",
                nickname: "The Stallion",
                description:
                    "Designed for larger-scale tasks. Ideal for home additions and new construction debris.",
            },
            {
                title: "40 Yard",
                nickname: "The Sovereign",
                description:
                    "Our largest size, perfect for significant projects. Can accommodate heavy, bulky materials.",
            },
        ],
        values: [
            {
                id: 7,
                number: "5-Star",
                name: "Rated Service",
                icon: AiFillStar,
            },
            {
                id: 3,
                number: "Same Day",
                name: "Delivery Available",
                icon: MdLocalShipping,
            },
            {
                id: 4,
                number: "2000+",
                name: "Happy Clients",
                icon: AiOutlineSmile,
            },
            {
                id: 5,
                number: "Competitive",
                name: "Pricing Guarantee",
                icon: FaHandHoldingUsd,
            },
        ],
        content: [
            <>
                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Chula Vista Dumpster Rental: Premier Dumpster Solutions in
                        Chula Vista, CA
                    </Heading>
                    Welcome to Chula Vista Dumpster Rental, where we provide top-notch
                    <a href={"https://www.puyallupdumpsterrentals.net/"} target={"_blank"} rel={"noreferrer"}>dumpster
                        rental</a> services in Chula Vista, California. Catering to a wide
                    range of needs, our services include residential and commercial
                    dumpster rentals, roll-off dumpster services, and eco-friendly waste
                    management solutions. Our commitment to efficient service and
                    environmental responsibility makes us a leading choice in the region.
                </Text>

                <Stack
                    direction={{
                        base: 'column',
                        lg: 'row',
                    }}
                    spacing={{
                        base: '12',
                        lg: '16',
                    }}
                    flex="1"
                >
                    <Box as="main" role="main" width="full" bg="bg.accent.default">
                        <Stack spacing={3}>
                            <Text>
                                <Heading as="h2" size={"md"} pb="2">
                                    Tailored Dumpster Rental Services
                                </Heading>
                                We offer a variety of specialized dumpster rental services to meet
                                your unique needs:
                                <UnorderedList>
                                    <ListItem>
                                        <Link href={"/residential-dumpster-rentals"}>
                                            Residential Dumpster Rentals
                                        </Link>
                                        : Tailored for homeowners in Chula Vista, our residential dumpsters
                                        streamline cleanouts, renovations, and landscaping projects,
                                        ensuring a clutter-free and efficient waste management experience.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/commercial-dumpster-rentals"}>
                                            Commercial Dumpster Rentals
                                        </Link>
                                        : Optimal waste solutions for businesses in Chula Vista. From
                                        office cleanouts to ongoing construction debris, our commercial
                                        dumpsters are designed for efficiency and reliability.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/roll-off-dumpster-rentals"}>
                                            Roll-Off Dumpster Rentals
                                        </Link>
                                        : Roll-off dumpsters offering unparalleled versatility for
                                        Chula Vista's diverse disposal needs. Perfect for large-scale
                                        construction, renovations, and extensive cleanouts.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/waste-management"}>
                                            Eco-Friendly Waste Management
                                        </Link>
                                        : Committed to Chula Vista's environment, our eco-friendly approach
                                        emphasizes recycling, responsible disposal, and sustainable
                                        practices in all waste management endeavors.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/same-day-dumpster-rentals"}>
                                            Same-Day Dumpster Rentals
                                        </Link>
                                        : Immediate <a href={'https://www.roswelldumpsters.com/'} target={'_blank'}
                                                       rel={'noreferrer'}>dumpster rental</a> solutions in Chula Vista
                                        for urgent disposal needs. Our same-day service ensures quick delivery, making
                                        it perfect for last-minute projects and tight deadlines.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/event-waste-management"}>
                                            Event Waste Management
                                        </Link>
                                        : Customized waste management services for events in Chula Vista.
                                        Whether it's a community festival, corporate event, or private
                                        gathering, our services keep your event clean and organized.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                                        : Efficient and eco-conscious <a href={'https://www.junkremovalgastonia.com/'}
                                                                         target={'_blank'}
                                                                         rel={'noreferrer'}>junk removal</a> services in
                                        Chula Vista. We handle everything from furniture and appliances to general
                                        clutter, making your space clean and livable.
                                    </ListItem>
                                    <ListItem>
                                        <Link href={"/long-term-dumpster-rentals"}>
                                            Long-Term Dumpster Rentals
                                        </Link>
                                        : Ideal for extended projects in Chula Vista requiring continuous
                                        waste management. Our long-term rental options offer flexibility,
                                        reliability, and consistent support.
                                    </ListItem>
                                </UnorderedList>
                                Our services are designed to provide efficient, hassle-free waste
                                management, whether for a small home project or a large commercial
                                undertaking.
                            </Text>
                        </Stack>
                    </Box>
                    <Box
                        as="aside"
                        role="complementary"
                        bg="bg.accent.default"
                        width={{
                            base: 'full',
                            lg: 'xl',
                        }}
                        alignSelf="center"
                        position={{
                            base: 'unset',
                        }}
                        top="36"
                    >
                        <Stack spacing={6}>
                            <Image src={dumpster4} alt={`dumpster rental chula vista`}/>
                        </Stack>
                    </Box>
                </Stack>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Why Partner with Chula Vista Dumpster Rental?
                    </Heading>
                    Choosing Chula Vista Dumpster Rental means opting for a service that
                    values:
                    <UnorderedList>
                        <ListItem>
                            Customized solutions to meet diverse waste disposal needs.
                        </ListItem>
                        <ListItem>
                            Efficient, reliable service with a focus on customer satisfaction.
                        </ListItem>
                        <ListItem>
                            Commitment to environmentally responsible waste management
                            practices.
                        </ListItem>
                        <ListItem>
                            Competitive pricing with transparent, no-hidden-fee policies.
                        </ListItem>
                        <ListItem>
                            A team of professionals dedicated to providing exceptional
                            service.
                        </ListItem>
                    </UnorderedList>
                    Our approach is rooted in understanding and addressing the unique
                    challenges faced by our clients in Chula Vista, ensuring a seamless and
                    effective dumpster rental experience.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Sustainability and Eco-Friendly Practices
                    </Heading>
                    At Chula Vista Dumpster Rental, we are passionate about protecting the
                    environment. We strive to implement sustainable waste management
                    practices, prioritizing recycling and responsible disposal methods.
                    Our goal is to reduce waste and promote environmental consciousness
                    within the Chula Vista community.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Reliable and Flexible Service
                    </Heading>
                    Whether you're managing a large-scale construction project or a small
                    residential cleanup, our team ensures that your dumpster needs are met
                    promptly and efficiently. We understand the importance of timely
                    service and offer flexible scheduling to accommodate your project
                    timeline.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Contact Chula Vista Dumpster Rental
                    </Heading>
                    For expert dumpster rental services in Chula Vista, CA,{" "}
                    <Link href={"tel:6196506638"}>call us at (619) 650-6638</Link>. Our
                    team is ready to assist you in selecting the right dumpster for your
                    project and provide you with a free, no-obligation quote. Experience
                    the Chula Vista Dumpster Rental difference – where your project's
                    success and environmental responsibility go hand in hand.
                </Text>

                <Button as={'a'} href={"tel:6196506638"} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                    below='md'>Immediate</Hide> Assistance</Button>
            </>,
        ],
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        Simplify home projects in Chula Vista with our quick deliveries and pickups.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        Enhance your business's waste handling with our customizable dumpster options.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        Tackle large waste disposal with ease using our versatile roll-off dumpsters in Chula Vista.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Conscious Waste Management:
                            </Link>
                        </b>{" "}
                        Sustainable waste practices with a focus on recycling and eco-friendliness.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Rapid, efficient dumpster delivery for urgent needs, without sacrificing service quality.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                        </b>{" "}
                        Custom waste handling for Chula Vista events, ensuring clean, organized venues.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                        </b>{" "}
                        Efficient, eco-friendly <a href={"https://www.junkremovalhighlandsranch.com/"} target={"_blank"}
                                                   rel={"noreferrer"}>junk removal</a> and clearing services in Chula
                        Vista, catering to all types of waste.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Ideal for prolonged projects in Chula Vista, offering flexible and dependable dumpster services.
                    </Text>
                ),
            },
        ],
        featuredServices: [
            {
                id: "0",
                name: "Residential Dumpster Rentals",
                path: "/residential-dumpster-rentals/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals
                            </Link>
                        </b>{" "}
                        When home projects beckon, Chula Vista Dumpster Rental answers! From
                        decluttering sessions to full-scale renovations, our range of
                        dumpsters fit every need. Serving Chula Vista households with timely
                        drop-offs and efficient pickups, we ensure a hassle-free waste
                        disposal experience. Discover the convenience Chula Vista residents
                        rave about.
                    </Text>
                ),
            },
            {
                id: "1",
                name: "Commercial Dumpster Rentals",
                path: "/commercial-dumpster-rentals/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Empowering businesses in Chula Vista, CA with top-notch waste
                        disposal solutions. Whether you're renovating an office or have
                        regular waste needs, our durable dumpsters are your trusted
                        partners. Flexible rental periods, swift services, and a{" "}
                        <Link href={"/blog/commercial-waste-management"}>commitment</Link>{" "}
                        to Chula Vista's business community set us apart. Dive into tailored
                        solutions with Chula Vista Dumpster Rental.
                    </Text>
                ),
            },
            {
                id: "2",
                name: "Roll-Off Dumpster Rentals",
                path: "/roll-off-dumpster-rentals/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Navigating waste disposal needs with adaptability and precision. Our
                        roll-off dumpsters, ideal for a spectrum of tasks, offer easy
                        loading and flexible placement options. Be it a home cleanout or a
                        commercial gig, witness unparalleled ease with our service.{" "}
                        <Link href={"/"}>Chula Vista Dumpster Rental</Link> guarantees
                        efficiency and reliability in every roll-off.
                    </Text>
                ),
            },
            {
                id: "3",
                name: "Waste Management",
                path: "/waste-management/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Friendly Waste Management
                            </Link>
                        </b>{" "}
                        We're not just about disposing; we're about recycling and
                        sustainability. Partner with a service that cherishes Chula Vista's
                        environment as much as you do. Our green processes prioritize
                        recycling, reducing landfill load, and keeping our community clean.
                        Be part of the green revolution with{" "}
                        <Link href={"/"}>Chula Vista Dumpster Rental</Link>.
                    </Text>
                ),
            },
            {
                id: "4",
                name: "Same-Day Dumpster Rentals",
                path: "/same-day-dumpster-rentals/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Need a dumpster quickly in Chula Vista? Our same-day rental service
                        provides rapid delivery to meet urgent waste disposal needs,
                        ensuring efficiency without compromising on service quality.
                    </Text>
                ),
            },
            {
                id: "5",
                name: "Event Waste Management",
                path: "/event-waste-management/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                        </b>{" "}
                        Specialized waste solutions for events in Chula Vista. Whether it's a
                        festival, corporate event, or wedding, our dumpsters ensure clean
                        and well-managed venues, tailored to your event's specific needs.
                    </Text>
                ),
            },
            {
                id: "6",
                name: "Junk Removal Services",
                path: "/junk-removal-services/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                        </b>{" "}
                        Comprehensive junk removal for Chula Vista residents. From old
                        furniture to general clutter, our service is quick, convenient, and
                        environmentally friendly, clearing the way for a cleaner space.
                    </Text>
                ),
            },
            {
                id: "7",
                name: "Long-Term Dumpster Rentals",
                path: "/long-term-dumpster-rentals/",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Tailored long-term dumpster rental solutions for ongoing projects in
                        Chula Vista. Our flexible rental periods and reliable service ensure
                        continuous support for your extended waste management needs.
                    </Text>
                ),
            },
        ],
        footerHeading: "Get in Touch with Chula Vista Dumpster Rental",
        footerText: (
            <>
                For all your{" "}
                <a
                    href="https://www.chulavistadumpsterrentals.com/"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    dumpster rental needs
                </a>{" "}
                in Chula Vista and surrounding areas, we are at your service. Reach out
                to us for any project, big or small, at any time. Whether you need a
                quick dumpster delivery or have queries about our services,{" "}
                <Link href={"tel:6196506638"}>call us at (619) 650-6638</Link> and we'll
                be happy to assist. With Chula Vista Dumpster Rental, your project's
                success is our priority.
            </>
        ),
        footerText2: "Dedicated to Excellence in Service and Customer Care",
        closingText:
            "At Chula Vista Dumpster Rentals, we are committed to providing efficient and reliable waste management solutions. With a variety of sizes to choose from, we are confident you will find the right dumpster to fit your needs. Contact us today at (619) 650-6638 to discuss your requirements.",
    },
    services: {
        metaData: {
            title:
                "Comprehensive Dumpster Rental Services in Chula Vista | Chula Vista Dumpster Rentals",
            description:
                "Discover our comprehensive dumpster rental services in Chula Vista. From construction debris to household junk, our reliable team is ready to handle all your waste management needs.",
        },
        url: "https://www.chulavistadumpsterrentals.com/services",
        h1: "Dumpster Rentals Services in Chula Vista, California",
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Chula Vista Dumpster Rental: Your Premier Waste Management Solution
                        in Chula Vista, CA
                    </Heading>
                    <Text>
                        Chula Vista Dumpster Rental is dedicated to providing top-notch
                        dumpster rental and waste management services in Chula Vista, CA.
                        Specializing in a range of solutions from residential cleanups to
                        commercial construction projects, we deliver reliable, efficient,
                        and environmentally responsible waste disposal services. Our
                        commitment to customer satisfaction and environmental stewardship
                        sets us apart as the leading service provider in the area.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose Chula Vista Dumpster Rental
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Versatile Dumpster Options:</b> Catering to both residential
                                and commercial needs, our diverse selection of dumpster sizes
                                ensures the right fit for every project.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Eco-friendly Practices:</b> We prioritize sustainable waste
                                disposal, focusing on recycling and reducing the environmental
                                impact of your waste.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Local Expertise:</b> Our deep understanding of Chula Vista's
                                regulations and community needs means efficient and compliant
                                service every time.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Flexible Rental Periods:</b> We offer adjustable rental
                                durations to suit your project timeline, providing convenience
                                and cost-effectiveness.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Customer-Centric Service:</b> Our team is committed to
                                exceptional service, ensuring your experience is smooth,
                                hassle-free, and tailored to your needs.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Our Comprehensive Dumpster Rental Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <Link href={"/residential-dumpster-rentals"}>
                                    <b>Residential Dumpster Rentals:</b>
                                </Link>{" "}
                                Perfect for home renovations, cleanouts, and landscaping
                                projects.{" "}
                                <Link href={"/residential-dumpster-rentals"}>
                                    Learn more about our residential services.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/commercial-dumpster-rentals"}>
                                    <b>Commercial Dumpster Rentals:</b>
                                </Link>{" "}
                                Ideal for businesses, construction sites, and large-scale waste
                                management.{" "}
                                <Link href={"/commercial-dumpster-rentals"}>
                                    Discover our commercial solutions.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/roll-off-dumpster-rentals"}>
                                    <b>Roll-Off Dumpster Rentals:</b>
                                </Link>{" "}
                                Flexible and convenient for projects requiring easy loading and
                                significant waste capacity.{" "}
                                <Link href={"/roll-off-dumpster-rentals"}>
                                    Explore our roll-off options.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/same-day-dumpster-rentals"}>
                                    <b>Same-Day Dumpster Rentals:</b>
                                </Link>{" "}
                                Quick solutions for urgent waste disposal needs.{" "}
                                <Link href={"/same-day-dumpster-rentals"}>
                                    Learn about our same-day service.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/long-term-dumpster-rentals"}>
                                    <b>Long-Term Dumpster Rentals:</b>
                                </Link>{" "}
                                Ideal for ongoing projects requiring continuous waste
                                management.{" "}
                                <Link href={"/long-term-dumpster-rentals"}>
                                    Find out about our long-term rentals.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/event-waste-management"}>
                                    <b>Event Waste Management:</b>
                                </Link>{" "}
                                Efficient waste solutions for events of all sizes, ensuring
                                cleanliness and compliance.{" "}
                                <Link href={"/event-waste-management"}>
                                    More on event rentals.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/junk-removal-services"}>
                                    <b>Junk Removal Services:</b>
                                </Link>{" "}
                                Comprehensive solutions for removing unwanted items and clutter.{" "}
                                See our junk removal services.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/waste-management"}>
                                    <b>Eco-Friendly Waste Management:</b>
                                </Link>{" "}
                                Committed to sustainable practices in every aspect of our
                                service.{" "}
                                <Link href={"/waste-management"}>
                                    Discover how we prioritize the environment.
                                </Link>
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Customized Solutions for Every Need
                    </Heading>
                    <Text>
                        We understand that every project is unique. That’s why Chula Vista
                        Dumpster Rental offers customized dumpster rental solutions to meet
                        the specific needs of your project, whether personal or commercial.
                        Our team is equipped to provide professional guidance and tailored
                        services, ensuring your waste management is efficient, effective,
                        and environmentally responsible.
                    </Text>

                    <Heading as="h3" size="lg">
                        Prioritizing Sustainability and Customer Satisfaction
                    </Heading>
                    <Text>
                        At Chula Vista Dumpster Rental, we are committed to not just meeting
                        but exceeding your expectations. Our focus on eco-friendly
                        practices, coupled with our dedication to customer satisfaction,
                        ensures a service that you can rely on and trust, every time.
                    </Text>

                    <Heading as="h3" size="lg">
                        Contact Chula Vista Dumpster Rental Today
                    </Heading>
                    <Text>
                        For comprehensive dumpster rental and waste management services in
                        Chula Vista, CA, look no further than Chula Vista Dumpster Rental.{" "}
                        <Link href={"/contact"}>Contact us</Link> today to discuss your
                        needs and find out how we can assist you in managing your waste
                        efficiently and responsibly.
                    </Text>
                </Stack>
            </Box>
        ),
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        We provide efficient and affordable dumpster rental services for
                        your home projects. Whether you're cleaning out your garage or
                        renovating your house, our residential dumpsters are perfect for all
                        types of household junk.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        Our commercial dumpster rental services are designed to handle
                        larger waste disposal needs. Ideal for construction sites, business
                        cleanouts, and large events.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals:
                            </Link>
                        </b>{" "}
                        Navigate bulky waste disposal seamlessly. Our roll-off dumpsters
                        offer adaptability for diverse projects. Witness the{" "}
                        <Link href={"/"}>Chula Vista Dumpster Rental</Link> excellence with
                        every container.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Conscious Waste Management:
                            </Link>
                        </b>{" "}
                        We are committed to disposing of your waste responsibly. We ensure
                        that recyclable materials are properly sorted and sent to recycling
                        facilities.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>Home Waste Solutions:</b> Dedicated to household projects, we
                        supply dumpsters ideal for regular household refuse, be it a
                        seasonal decluttering or an after-festivity cleanup.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>Corporate Cleanup Solutions:</b> Our containers are perfect for
                        businesses, addressing extensive waste needs from office
                        decluttering to event clean-up.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>Urgent Waste Solutions:</b> Require a dumpster on short notice?
                        Our express services cater to eleventh-hour needs, ensuring your
                        waste is promptly managed.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Tailored long-term dumpster rental solutions for ongoing projects in
                        Chula Vista. Our flexible rental periods and reliable service ensure
                        continuous support for your extended waste management needs.
                    </Text>
                ),
            },
        ],
    },
    contact: {
        url: "https://www.chulavistadumpsterrentals.com/contact",
        metaData: {
            title:
                "Contact Chula Vista Dumpster Rentals: Quick and Reliable Service | Call 619-650-6638",
            description:
                "Contact Chula Vista Dumpster Rentals for efficient and reliable waste management services. We're here to help with all your dumpster rental needs in Chula Vista.",
        },
        h1: "Call Us to Get a Price on a Dumpster",
        subtitle:
            "Thank you for choosing Chula Vista Dumpster Rentals. We are a dedicated waste management company servicing Chula Vista and surrounding areas, offering swift and reliable dumpster rental services to our valued customers. If you require immediate assistance, don't hesitate to reach out to us. Our professional and friendly team is here to help.",
        sectionHeading: "Why Choose Chula Vista Dumpster Rentals?",
        signUpDescription:
            "If you prefer to reach out to us online, please fill out the contact form below. We will get back to you as soon as possible.",
        feedbackText:
            "We value feedback. If you have any questions or concerns, we'd love to hear from you. Your satisfaction is our top priority.",
        closingText:
            "Prompt, professional, and affordable waste management solutions in Chula Vista.",
        valueProps: [
            {
                title: "Swift Service:",
                description:
                    "We understand that waste management needs can arise at any moment, so our reliable team is always ready to deliver and pick up your dumpster promptly and efficiently. We take great pride in our customer service and won't keep you waiting.",
            },
            {
                title: "Various Dumpster Sizes:",
                description:
                    "At Chula Vista Dumpster Rentals, we offer dumpsters in various sizes to suit different waste management needs. Whether you're dealing with household junk or construction debris, we have the right dumpster for you.",
            },
            {
                title: "Competitive Rates:",
                description:
                    "We offer competitive rates for all our dumpster rental services. Our pricing is transparent, with no hidden fees, ensuring you get the best value for your money.",
            },
            {
                title: "Eco-friendly Disposal:",
                description:
                    "We are committed to eco-friendly waste disposal practices. We ensure that recyclable materials are properly sorted and sent to recycling facilities.",
            },
            {
                title: "Customer Satisfaction:",
                description:
                    "At Chula Vista Dumpster Rentals, customer satisfaction is our highest priority. We strive to exceed your expectations by providing superior service and making your waste management tasks as hassle-free as possible.",
            },
        ],
    },
    blog: {
        url: "https://www.chulavistadumpsterrentals.com/blog",
        metaData: {
            title:
                "Chula Vista Dumpster Rentals: Efficient & Affordable Waste Management | Blog",
            description:
                "Efficient and affordable dumpster rentals in Chula Vista. Your partner for waste management needs. Contact us for quick and reliable dumpster rental services at competitive prices.",
        },
        h1: "Chula Vista Dumpster Rentals Articles | Insights and Tips",
        h2: "Our Blog",
        heading:
            "Chula Vista Dumpster Rentals is here to serve you with professionalism, efficiency, and affordability. Whether you require immediate dumpster rental or planned waste disposal, our team is just a phone call away.",
        posts: posts,
    },
    about: {
        url: "https://www.chulavistadumpsterrentals.com/about/",
        metaData: {
            title:
                "About Chula Vista Dumpster Rental - Premier Dumpster Solutions in Chula Vista, CA",
            description:
                "Learn more about Chula Vista Dumpster Rental, your expert in environmentally responsible and efficient waste management solutions in Chula Vista, CA.",
        },
        h1: "About Chula Vista Dumpster Rental",
        h2: [
            "Our Commitment to Sustainable Waste Management",
            "Experienced Team, Unmatched Service",
            "Diverse Dumpster Rental Services for Every Need",
        ],
        headingText: {
            commitment:
                "Dedicated to providing environmentally conscious and customer-focused dumpster rental services in Chula Vista, California.",
            team: "Our team of waste management experts is equipped with knowledge and experience, ensuring exceptional service for every client.",
            services:
                "Explore our extensive range of dumpster rental services, tailored for both residential and commercial needs, ensuring effective waste disposal solutions.",
        },
        services: [
            {
                id: "0",
                title: "Residential Dumpster Rentals",
                href: "/residential-dumpster-rentals",
                icon: "MdHome",
                description: (
                    <Text>
                        Perfect for your home renovation or cleanout projects in Chula Vista.
                        Experience hassle-free waste disposal with our reliable residential
                        dumpster services.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Commercial Dumpster Rentals",
                href: "/commercial-dumpster-rentals",
                icon: "FaBuilding",
                description: (
                    <Text>
                        Elevate the efficiency of waste management at your business with our
                        flexible commercial dumpster rental options, catering to a variety
                        of industries in Chula Vista.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Roll-Off Dumpster Rentals",
                href: "/roll-off-dumpster-rentals",
                icon: "MdOutlineRoller",
                description: (
                    <Text>
                        Our roll-off dumpsters provide the ultimate solution for large-scale
                        waste disposal needs, ideal for construction sites and extensive
                        cleanups.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Eco-Friendly Waste Management",
                href: "/waste-management",
                icon: "GiRecycle",
                description: (
                    <Text>
                        Committed to sustainable practices, we offer eco-friendly waste
                        management services, focusing on recycling and responsible disposal
                        in Chula Vista.
                    </Text>
                ),
            },
        ],
    },
    testimonials: {
        h1: "What Our Clients Say",
        heading:
            "Explore what our satisfied customers in Chula Vista have to say about our dumpster rental services. Our commitment to quality service and customer satisfaction speaks for itself.",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e34027805e",
                name: "Emily R.",
                logo: undefined,
                title: "Chula Vista, CA",
                quote:
                    '"Chula Vista Dumpster Rental made my home renovation project so much easier! They delivered the dumpster right on time and placed it exactly where we needed. Their team was friendly and very professional. I highly recommend their service for any cleanup needs!"',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425f4e",
                name: "John D.",
                logo: undefined,
                title: "Chula Vista, CA",
                quote:
                    '"I have used Chula Vista Dumpster Rental for several construction projects. Their dumpsters are always clean and in great condition. The delivery and pickup are always prompt and hassle-free. They are my go-to company for all my dumpster needs in Chula Vista."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=b0537f4d",
                name: "Rachel M.",
                logo: undefined,
                title: "Chula Vista, CA",
                quote:
                    '"I’m impressed with the efficiency of Chula Vista Dumpster Rental. They offered me an affordable rate and excellent customer service. The whole process from ordering to pickup was smooth and easy. Definitely the best choice for dumpster rentals in Chula Vista."',
            },
        ],
    },
    residentialDumpsterRentals: {
        url: "https://www.chulavistadumpsterrentals.com/residential-dumpster-rentals/",
        metaData: {
            title: "Residential Dumpster Rental Services | Chula Vista CA",
            description:
                "Streamline your home improvement tasks with Chula Vista Dumpster Rental. Cost-effective, straightforward solutions for homeowners in Chula Vista, CA. Explore our offerings!",
        },
        h1: "Chula Vista's Premier Residential Dumpster Rental Provider",
        subtitle:
            "Custom Solutions for Home Endeavors - From Remodeling to Decluttering",
        body: <ResidentialDumpster/>,
    },
    commercialDumpsterRentals: {
        url: "https://www.chulavistadumpsterrentals.com/commercial-dumpster-rentals/",
        metaData: {
            title: "Business Dumpster Rentals in Chula Vista, CA",
            description:
                "From workspace clear-outs to large-scale construction jobs, Chula Vista Dumpster Rental delivers trusted solutions. Engage with Chula Vista’s top-tier commercial dumpster service.",
        },
        h1: "Commercial Dumpster Solutions in Chula Vista, CA",
        subtitle:
            "Optimized Waste Management for Chula Vista's Thriving Enterprises",
        body: <CommercialDumpster/>,
    },
    rollOffDumpsterRentals: {
        url: "https://www.chulavistadumpsterrentals.com/roll-off-dumpster-rentals/",
        metaData: {
            title:
                "Roll-Off Dumpster Services | Adaptable Disposal Solutions | Chula Vista CA",
            description:
                "Seeking dynamic waste removal options? Dive into roll-off dumpster rentals from Chula Vista Dumpster Rental. Perfect for households and businesses in Chula Vista, CA.",
        },
        h1: "Roll-Off Dumpster Services for Adaptable Waste Handling in Chula Vista",
        subtitle: "Your First Pick for Flexible and Proficient Waste Removal.",
        body: <RollOffDumpster/>,
    },
    wasteManagement: {
        url: "https://www.chulavistadumpsterrentals.com/waste-management/",
        metaData: {
            title:
                "Eco-Conscious Dumpster Rental | Ethical Waste Handling | Chula Vista CA",
            description:
                "Opt for a sustainable waste removal path. Chula Vista Dumpster Rental is devoted to eco-friendly methods, fostering a brighter tomorrow for Chula Vista, CA.",
        },
        h1: "Green Waste Management Strategies in Chula Vista",
        subtitle:
            "Pledging for a Cleaner, Sustainable Chula Vista with Every Dumpster.",
        body: <WasteManagement/>,
    },
    sameDayDumpsterRental: {
        url: "https://www.chulavistadumpsterrentals.com/same-day-dumpster-rentals/",
        metaData: {
            title:
                "Same-Day Dumpster Rental in Chula Vista CA | Quick & Reliable Service",
            description:
                "Need a dumpster today? Chula Vista Dumpster Rental offers same-day services in Chula Vista, CA. Fast, efficient, and reliable - perfect for your urgent disposal needs.",
        },
        h1: "Fast and Efficient Same-Day Dumpster Rentals in Chula Vista",
        subtitle:
            "Your Go-To Solution for Immediate Dumpster Needs in Chula Vista, CA.",
        body: <SameDayDumpster/>,
    },
    eventWasteManagement: {
        url: "https://www.chulavistadumpsterrentals.com/event-waste-management/",
        metaData: {
            title: "Event Waste Management in Chula Vista CA | Clean & Efficient",
            description:
                "Ensure a clean and orderly event in Chula Vista, CA with our specialized waste management services. Perfect for festivals, corporate events, and private gatherings.",
        },
        h1: "Professional Event Waste Management Services in Chula Vista",
        subtitle: "Keeping Your Events in Chula Vista Clean and Green.",
        body: <EventWasteManagement/>,
    },
    junkRemovalServices: {
        url: "https://www.chulavistadumpsterrentals.com/junk-removal-services/",
        metaData: {
            title:
                "Efficient Junk Removal Services in Chula Vista CA | Clutter-Free Solutions",
            description:
                "Get rid of unwanted junk in Chula Vista, CA, with our reliable junk removal services. Quick, eco-friendly, and hassle-free - clearing the way for a cleaner space.",
        },
        h1: "Comprehensive Junk Removal Services in Chula Vista",
        subtitle:
            "Effortlessly Clearing Out Clutter from Your Chula Vista Property.",
        body: <JunkRemovalServices/>,
    },
    longTermDumpsterRental: {
        url: "https://www.chulavistadumpsterrentals.com/long-term-dumpster-rentals/",
        metaData: {
            title:
                "Long-Term Dumpster Rentals in Chula Vista CA | Flexible & Reliable",
            description:
                "For extended projects in Chula Vista, CA, choose our long-term dumpster rentals. Flexible rental periods, reliable service, and consistent waste management support.",
        },
        h1: "Reliable Long-Term Dumpster Rental Services in Chula Vista",
        subtitle:
            "Your Partner in Sustained Waste Management for Long-Term Projects.",
        body: <LongTermDumpsterRentals/>,
    },
    404: {
        metaData: {
            title:
                "Page Not Found - Chula Vista Dumpster Rentals | Reliable Service | (619) 650-6638",
            description:
                "Oops! The page you're looking for could not be found. Chula Vista Dumpster Rentals offers professional and reliable waste disposal services. Contact us for assistance.",
            siteName:
                "Dumpster Rentals in Chula Vista, CA | Reliable Service | (619) 650-6638",
        },
        h1: "404 - Oops, it seems you've found a page that's missing.",
        subtitle: "We apologize, but the page you are looking for does not exist.",
        p: "Whether you require a residential, commercial, or construction dumpster rental, Chula Vista Dumpster Rentals has got you covered. Give us a call any time at (619) 650-6638 for prompt and professional service.",
    },
};
