import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

export const JunkRemovalServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Premium Junk Removal Services in Chula Vista, CA
        </Heading>
        <Text>
          Tackling unwanted clutter has never been easier with Chula Vista
          Dumpster Rental's top-notch junk removal services. Serving both
          residential and commercial needs in Chula Vista, CA, our dedicated
          team is equipped to efficiently and responsibly handle everything from
          old furniture to appliances and general clutter.
        </Text>
        <Text>
          Our junk removal service is not just about disposing of unwanted
          items; it's a commitment to providing a stress-free and eco-friendly
          solution to decluttering. We understand the challenges of large-scale
          cleanouts and are here to simplify the process, leaving your space
          clean and clear.
        </Text>
        <Heading as={"h3"} size={"lg"}>
          Why Opt for Our Junk Removal Services?
        </Heading>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Professional and Prompt Service:</strong> Experience timely
            and efficient junk removal with our expert team.
          </ListItem>
          <ListItem>
            <strong>Eco-friendly Disposal:</strong> Prioritizing recycling and
            responsible disposal, we minimize environmental impact.
          </ListItem>
          <ListItem>
            <strong>Versatile Capabilities:</strong> From residential to
            commercial spaces, no job is too big or small for us.
          </ListItem>
          <ListItem>
            <strong>Customized Solutions:</strong> We tailor our services to
            meet your specific junk removal needs and schedule.
          </ListItem>
        </UnorderedList>
        <Text>
          Our commitment to customer satisfaction and environmental
          responsibility sets us apart in Chula Vista. We go the extra mile to
          ensure that your junk removal experience is seamless and eco-friendly.
        </Text>
        <Heading as={"h3"} size={"lg"}>
          Our Streamlined Junk Removal Process
        </Heading>
        <Text>
          Step by step, we guarantee a hassle-free junk removal experience. Our
          process starts with a consultation to assess your needs, followed by
          scheduling a convenient pickup time. Our team arrives prepared to
          handle all the heavy lifting, sorting, and transportation of your
          junk. We prioritize responsible disposal, focusing on recycling and
          donation where possible.
        </Text>
        <Heading as={"h3"} size={"lg"}>
          Contact Us for Your Junk Removal Needs in Chula Vista
        </Heading>
        <Text>
          Ready to reclaim your space from clutter?{" "}
          <Link href={"tel:6196506638"}>Call us at 619-650-6638</Link> to
          discuss your junk removal needs in Chula Vista, CA. Our team is ready
          to provide you with a free estimate and assist you in achieving a
          clutter-free environment.
        </Text>
        <Text>
          Explore our range of services, including{" "}
          <Link href={"/same-day-dumpster-rentals"}>
            Same-Day Dumpster Rentals
          </Link>
          , <Link href={"/event-waste-management"}>Event Waste Management</Link>
          , and{" "}
          <Link href={"/roll-off-dumpster-rentals"}>
            Roll-Off Dumpster Rentals
          </Link>
          , to cover all your waste management requirements. Trust Chula Vista
          Dumpster Rental for all your disposal needs.
        </Text>
      </Stack>
    </Box>
  );
};
