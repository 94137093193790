import React from 'react';
import BlogCards01 from '../../Components/BlogCards/BlogCards01';
import LatestNews from '../../Components/LatestNews';
import {Box} from "@chakra-ui/react";
import useBlogPosts from "../../Hooks/useBlogPosts";

const Blog = () => {
    const {posts} = useBlogPosts()
    return (
        <Box className="blog section">
            <div className="container">
                <div className="blog_grid">
                    <div className="row">
                        <LatestNews />

                        {
                            posts.slice(0, 2).map(data => <BlogCards01
                                key={data.id}
                                data={data}
                            />)
                        }
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default Blog;