import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

export const CommercialDumpster = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Premier Commercial Dumpster Rentals in Chula Vista, CA
        </Heading>
        <Text>
          Streamline your business waste management with Chula Vista Dumpster
          Rental, your trusted partner for efficient and reliable commercial
          dumpster solutions. Tailored to meet the unique needs of various
          industries, our diverse range of dumpsters ensures a seamless waste
          disposal experience, regardless of your business's size or type.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Customized Dumpster Options:</strong> Whether you operate a
            retail store, construction site, or office complex, we offer a
            variety of dumpster sizes to suit your specific requirements.
          </ListItem>
          <ListItem>
            <strong>Effortless & Punctual Service:</strong> Enjoy hassle-free
            scheduling with our prompt drop-offs and pickups, minimizing any
            disruption to your business operations and keeping your workflow
            intact.
          </ListItem>
          <ListItem>
            <strong>Sustainable Waste Management:</strong> Committed to
            environmental responsibility, we handle your commercial waste with a
            focus on green initiatives, promoting eco-friendly practices and
            reducing your carbon footprint.
          </ListItem>
          <ListItem>
            <strong>Dependable & Professional:</strong> Businesses throughout
            Chula Vista trust in our expertise, transparency, and exceptional
            customer service to meet their commercial waste disposal needs.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
